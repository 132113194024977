import React, { useEffect, useState } from "react"
import DashboardLayout from "layout/DashboardLayout"

import Message from "elements/Message"

import _ from "lodash"

import Loading from "elements/Loading"

import EmployeeCard from "./EmployeeCard.js"

import {
  getTimesheetsToday,
  getDeputyEmployee,
  updateActiveMedstaffPool,
} from "services/deputy"

import { getSignedInUser } from "../Auth/services/user"
//1. takes in an employee list based on the selected option
//2. render each employee in a card 1/4 column

const EmployeeCards = ({ groupedEmployees, message }) => {
  if (groupedEmployees[0].length) {
    return groupedEmployees.map((group) => {
      return (
        <div className="columns">
          {group.map((employee) => {
            return <EmployeeCard employee={employee} />
          })}
        </div>
      )
    })
  } else {
    return <Message color="warning">{message}</Message>
  }
}

const SelectEmployee = ({ pageContext }) => {
  //

  const [groupedEmployees, setGroupedEmployees] = useState([[]])
  const [pageLoading, setPageLoading] = useState(true)
  const [message, setMessage] = useState(null)

  const { organization } = getSignedInUser()

  let getActiveEmployeeTimesheets = async () => {
    let timesheets =
      (await getTimesheetsToday({
        companyId: organization.deputyLocationId,
      })) || []
    let fetchedEmployees = []

    if (timesheets?.length) {
      let employeesInTimesheets = timesheets.map((timesheet) => {
        return {
          id: timesheet["Employee"],
          startTimeLocalized: timesheet["StartTimeLocalized"],
          endTimeLocalized: timesheet["EndTimeLocalized"],
        }
      })

      employeesInTimesheets.forEach(async (employee) => {
        let deputyEmployee = await getDeputyEmployee({
          employeeId: employee.id,
        })

        fetchedEmployees = [
          ...fetchedEmployees,
          { ...employee, ...deputyEmployee },
        ]

        setGroupedEmployees(_.chunk(fetchedEmployees, 4))
      })

      await updateActiveMedstaffPool()
    } else {
      setMessage(
        `No active timesheets found for ${organization.organizationTradeName}. Please deputy in via the clinic phone and refresh this page.`
      )
    }
    setPageLoading(false)
  }

  useEffect(() => {
    getActiveEmployeeTimesheets()

    //eslint-disable-next-line
  }, [])

  return (
    <DashboardLayout title={pageContext?.module?.title || "Nurses"}>
      {pageLoading ? (
        <Loading />
      ) : (
        <EmployeeCards groupedEmployees={groupedEmployees} message={message} />
      )}
    </DashboardLayout>
  )
}

export default SelectEmployee
