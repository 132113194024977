import React from "react"

import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"

import styles from "./utils/deputy.module.scss"

import { getElapsedTime } from "services/general"
//1. takes in an employee list based on the selected option
//2. render each employee in a card 1/4 column

const EmployeeCard = ({ employee }) => {
  return (
    <div
      className={classNames(
        "column has-text-centered card my-2 mx-1 is-one-quarter",
        styles["employeeCardContainer"]
      )}
    >
      <div className="card-content py-0 px-0">
        <div
          className={classNames(
            "has-text-centered has-text-white",
            styles["employeeCardContainer__photo"]
          )}
        >
          <FontAwesomeIcon
            className={classNames(styles["employeeCardContainer__icon"])}
            icon={faUserMd}
          />
        </div>
      </div>

      {/* <figure class="image has-text-centered mx-0 is-square">
                  <img src="https://bulma.io/images/placeholders/128x128.png" />
                </figure> */}

      <div className="content has-text-primary pt-1 mb-1 has-text-weight-bold is-size-5">
        {employee["DisplayName"]}
      </div>
      <p className="has-text-primary is-size-5">Shift Duration:</p>

      <div className="content has-text-primary  has-text-weight-bold has-text-centered is-size-4">
        {getElapsedTime({
          startTime: employee["startTimeLocalized"],
        })}
      </div>
    </div>
  )
}

export default EmployeeCard
