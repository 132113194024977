import axios from "axios"
import moment from "moment"

import { getSignedInUser } from "../components/Auth/services/user"

import { isBrowser } from "services/general"

import {
  GATSBY_DEPUTY_FIREBASE_FUNCTION_URL,
  GATSBY_DEPUTY_ACCESS_CODE,
  GATSBY_DEPUTY_BASE_API_URL,
} from "gatsby-env-variables"

export const deputy = () => {
  let api = axios.create({
    baseURL: GATSBY_DEPUTY_FIREBASE_FUNCTION_URL,
    headers: {
      Authorization: "OAuth " + GATSBY_DEPUTY_ACCESS_CODE,
      contentType: "application/json",
      api_url: GATSBY_DEPUTY_BASE_API_URL,
    },
  })

  return api
}

export const getDeputyEmployee = async ({ employeeId }) => {
  let result = await deputy().post(`/employee?employeeId=${employeeId}`)
  return result.data || {}
}

export const getDeputyEmployeeAreas = async ({ companyId }) => {
  let result = await deputy().post(`/area?companyId=${companyId}`)

  let areaOptions = []
  if (result.data) {
    areaOptions = result.data.map((area) => {
      return {
        label: area["OperationalUnitName"],
        value: area["Id"],
      }
    })
  }

  return areaOptions
}

//update this to only get timesheets up to the day before
export const getTimesheetsToday = async () => {
  const { organization } = getSignedInUser()
  let results
  if (organization.id) {
    let requestBody = {
      search: {
        company: {
          field: "Company",
          data: organization?.deputyLocationId,
          type: "eq",
          join: "OperationalUnitObject",
        },

        fromDate: {
          field: "Created",
          type: "ge",
          data: moment().subtract(1, "day").format("YYYY-MM-DD hh:mm A"),
        },
        isInProgress: {
          field: "IsInProgress",
          type: "eq",
          data: true,
        },
      },
    }

    results = await deputy().post("/timesheet", requestBody)
  }

  return results.data
}

//this function will update medstaff pool in session storage. Result of this will be used as available options for any function that needs to reference the current active pool.
export const updateActiveMedstaffPool = async () => {
  const { medstaff } = getSignedInUser()

  let activeTimesheets = await getTimesheetsToday()

  activeTimesheets = activeTimesheets.map((timesheet) => {
    return { ...timesheet, employee: timesheet["_DPMetaData"]["EmployeeInfo"] }
  })

  let updatedMedstaff = medstaff
    .map((staff) => {
      let medstaffTimesheet = activeTimesheets.find((timesheet) => {
        return (
          timesheet["Employee"].toString() === staff.deputyEmployeeId.toString()
        )
      })
      if (medstaffTimesheet) {
        return { ...staff, timesheet: medstaffTimesheet }
      } else return null
    })
    .filter((updatedStaff) => !!updatedStaff)

  if (isBrowser())
    sessionStorage.setItem("activeMedstaff", JSON.stringify(updatedMedstaff))
}

// export const startTimesheet = async () => {}
